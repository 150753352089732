<template>
  <div class="main">
    <div class="fun">
      <el-radio-group v-model="sdkType.configValue" @change="onSdkChange">
        <el-radio label="TENCENT">腾讯人脸识别SDK</el-radio>
        <el-radio label="CNCAr">网证通SDK</el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "lookFace",
  data() {
    return {
      SDK: "tencent",
      sdkType: {
        configId: 1,
        configValue: "",
      },
    };
  },
  mounted() {
    this.getLookFaceList();
  },
  methods: {
    // 获取信息
    getLookFaceList() {
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/sapi/v1/system/configs`,
        params: {
          configType: "IDENTIFICATION",
        },
      }).then((res) => {
        if (res.data.code == "200") {
          Object.assign(this.sdkType, res.data.data.sdkType);
        }
      });
    },
    // 监听 SDK 切换
    onSdkChange(e) {
      this.$axios({
        method: "PUT",
        url: `${this.apiUrlCros}/sapi/v1/system/configs/save`,
        data: {
          configs: [
            {
              configId: 1,
              configValue: e,
            },
          ],
        },
      })
        .then((res) => {
          if (res.data.code == "200") {
            this.$Message.success("设置成功");
          } else {
            this.$Message.error(res.data.msg);
            this.getLookFaceList();
          }
        })
        .catch((err) => {
         // this.$Message.error("服务器异常");
          this.getLookFaceList();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.main {
  width: 100%;
  height: 100%;
  .fun {
    padding: 14px;
    width: 100%;
  }
}
</style>