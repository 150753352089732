<template>
  <div class="main">
    <div class="fun">
      <div class="title">
        <h2>平台数字证书开关</h2>
        <!-- <Button type="primary" >{{platformNumberCard.switchStatus?'关闭':'开启'}}</Button> -->
        <el-switch
          v-model="platformNumberCard.switchStatus"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="onPlatformSwitchChange"
        >
        </el-switch>
      </div>
      <div class="radio" @change="onGrade">
        <el-radio-group v-model="grade" size="medium">
          <el-radio-button label="CORP">企业级别</el-radio-button>
          <el-radio-button label="PROJECT">项目级别</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div v-if="grade == 'CORP'" class="cont">
      <div style="margin-bottom: 15px">
        <div style="display: flex; align-items: center">
          <div class="label">企业名称：</div>
          <!-- <Input
            placeholder="请输入企业名称"
            style="position:fixed;bottom:-999999px;"
            autocomplete="off"
            clearable
          /> -->
          <Input
            placeholder="请输入企业名称"
            style="width: 200px; margin: 0 14px"
            v-model="corpSearchData.keyword"
            autocomplete="off"
            clearable
          />
          <Input
            placeholder="请输入企业名称"
            style="position: fixed; bottom: -999999px"
            autocomplete="off"
            clearable
          />
          <!-- <el-select v-model="corpName" placeholder="请选择企业" @change="onCorpChange">
            <el-option label="所有企业" value=""></el-option>
            <el-option
              v-for="item in corpOptions"
              :key="item.id"
              :label="item.vname"
              :value="item.id">
            </el-option>
          </el-select> -->
          <Button
            type="primary"
            style="margin-right: 14px"
            @click="
              () => {
                this.corpPageData.current = 1;
                this.getCorpNumberCardConfigList();
              }
            "
            >搜索</Button
          >
        </div>
        <Table
          highlight-row
          ref="corp"
          :columns="corptCloumsList"
          :data="corpDataList"
          :loading="corpTableLoading"
          style="margin: 10px 0 5px"
        >
          <template slot-scope="{ row, index }" slot="index">
            <span>{{ index + 1 }}</span>
          </template>
          <template slot-scope="{ row }" slot="switchStatus">
            <el-switch
              v-model="row.switchStatus"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="onCorpSwitchChange(row.dataId, row.switchStatus)"
            >
            </el-switch>
          </template>
        </Table>
        <div class="fun-page-box">
          <page
            show-total
            :total="corpPageData.total"
            :page-size="corpPageData.size"
            :current="corpPageData.current"
            @on-change="corpPageChange"
          />
        </div>
      </div>
    </div>
    <div v-else class="cont">
      <div style="margin-bottom: 15px">
        <div style="display: flex; align-items: center">
          <div class="label">企业或项目名称：</div>
          <!-- <Input
            placeholder="请输入企业名称"
            style="position:fixed;bottom:-999999px;"
            autocomplete="off"
            clearable
          /> -->
          <Input
            placeholder="请输入企业或项目名称"
            style="width: 200px; margin: 0 14px"
            v-model="projectSearchData.keyword"
            autocomplete="off"
            clearable
          />
          <Input
            placeholder="请输入企业或项目名称"
            style="position: fixed; bottom: -999999px"
            autocomplete="off"
            clearable
          />
          <Button
            type="primary"
            style="margin-right: 14px"
            @click="
              () => {
                this.projectPageData.current = 1;
                this.getProjectNumberCardConfigList();
              }
            "
            >搜索</Button
          >
        </div>
        <Table
          highlight-row
          ref="project"
          :columns="projectCloumsList"
          :data="projectDataList"
          :loading="projectTableLoading"
          style="margin: 10px 0 5px"
        >
          <template slot-scope="{ row, index }" slot="index">
            <span>{{ index + 1 }}</span>
          </template>
          <template slot-scope="{ row }" slot="switchStatus">
            <el-switch
              v-model="row.switchStatus"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="onProjectSwitchChange(row.dataId, row.switchStatus)"
            >
            </el-switch>
          </template>
        </Table>
        <div class="fun-page-box">
          <page
            show-total
            :total="projectPageData.total"
            :page-size="projectPageData.size"
            :current="projectPageData.current"
            @on-change="projectPageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "numberCard",
  data() {
    return {
      grade: "CORP",
      corpName: undefined,
      corpOptions: [],
      platformNumberCard: {
        configType: "PLATFORM",
        dataId: 0,
        corpName: null,
        projectName: null,
        switchStatus: true,
      },
      corpPageData: {
        total: 0,
        size: 20,
        current: 1,
      },
      corpSearchData: {
        keyword: "",
      },
      corptCloumsList: [
        {
          title: "序号",
          slot: "index",
          width: "50",
        },
        {
          title: "企业名称",
          key: "corpName",
          align: "center",
        },
        {
          title: "数字证书开关",
          slot: "switchStatus",
          align: "center",
        },
      ],
      corpDataList: [],
      corpTableLoading: false,
      projectPageData: {
        total: 0,
        size: 20,
        current: 1,
      },
      projectSearchData: {
        keyword: "",
      },
      projectCloumsList: [
        {
          title: "序号",
          slot: "index",
          width: "50",
        },
        {
          title: "企业名称",
          key: "corpName",
          align: "center",
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
        },
        {
          title: "数字证书开关",
          slot: "switchStatus",
          align: "center",
        },
      ],
      projectDataList: [],
      projectTableLoading: false,
    };
  },
  mounted() {
    // this.getCompanyDataList()
    this.getPlatformNumberCardConfigList("PLATFORM");
    this.getCorpNumberCardConfigList();
  },
  methods: {
    // 获取平台数字开关配置
    getPlatformNumberCardConfigList() {
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/sapi/v1/certificate/configs`,
        params: {
          configType: "PLATFORM",
        },
      }).then((res) => {
        if (res.data.code == "200") {
          Object.assign(this.platformNumberCard, res.data.data.dataList[0]);
        }
        //if(res.data.code=="200") {
        // }
      });
    },
    // 获取企业数字开关列表
    getCorpNumberCardConfigList() {
      this.corpTableLoading = true;
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/sapi/v1/certificate/configs`,
        params: {
          configType: "CORP",
          // corpId: this.corpName || "",
          keyword: this.corpSearchData.keyword || "",
          pageNo: this.corpPageData.current,
          pageSize: this.corpPageData.size,
        },
      })
        .then((res) => {
          if (res.data.code == "200") {
            this.corpPageData.total = res.data.data.totalNum;
            this.corpDataList = res.data.data.dataList;
          }
        })
        .finally(() => {
          this.corpTableLoading = false;
        });
    },
    // onSearchCorp() {
    //   console.log(this.corpSearchData)
    // },
    // 监听企业列表页码
    corpPageChange(e) {
      this.corpPageData.current = e;
      if(this.corpSearchData.keyword!="") {
        this.corpPageData.current = 1
      }
      this.getCorpNumberCardConfigList();
    },
    // 获取项目数字开关列表
    getProjectNumberCardConfigList() {
      this.projectTableLoading = true;
      this.$axios({
        method: "get",
        url: `${this.apiUrlCros}/sapi/v1/certificate/configs`,
        params: {
          configType: "PROJECT",
          // corpId: this.corpName || "",
          keyword: this.projectSearchData.keyword || "",
          pageNo: this.projectPageData.current,
          pageSize: this.projectPageData.size,
        },
      })
        .then((res) => {
          if (res.data.code == "200") {
            this.projectPageData.total = res.data.data.totalNum;
            this.projectDataList = res.data.data.dataList;
          }
        })
        .finally(() => {
          this.projectTableLoading = false;
        });
    },
    // 监听项目列表页码
    projectPageChange(e) {
      this.projectPageData.current = e;
      if(this.projectSearchData.keyword!="") {
        this.projectPageData.current = 1
      }
      this.getProjectNumberCardConfigList();
    },
    // 获取公司列表
    getCompanyDataList() {
      this.$axios
        .get(`${this.apiUrlCros}/sapi/corp/superadmin/list?pageNo=1&pageSize=100`)
        .then((res) => {
          if (res.data.code == "200") {
            this.corpOptions = res.data.data.records;
          }
        });
    },
    // 获取项目列表
    getProjectDataList() {
      this.$axios
        .get(
          `${this.apiUrlCros}/sapi/project/superadmin/list?pageNo=1&pageSize=100&corpId=${node.value}`
        )
        .then((res) => {
          if (res.data.code == "200") {
            const projectData = res.data.data.records.map((value, i) => ({
              value: value.id,
              label: value.name,
              children: undefined,
              leaf: node.level >= 0,
            }));
            node.loading = false;
            node.loaded = true;
            resolve(projectData);
          }
        });
    },
    // 监听等级切换
    onGrade(e) {
      if (this.grade == "PROJECT") {
        this.getProjectNumberCardConfigList();
      }
    },
    // 监听企业选择
    onCorpChange(e) {
      this.getCorpNumberCardConfigList();
    },
    // 数字开关
    setSwitch(configType, dataId, switchStatus) {
      this.$axios({
        method: "post",
        url: `${this.apiUrlCros}/sapi/v1/certificate/configs/save`,
        data: {
          configType,
          dataId,
          switchStatus,
        },
      }).then((res) => {
        if (res.data.code == "200") {
          this.$Message.success("设置成功");
        }
      });
    },
    // 平台开关
    onPlatformSwitchChange(e) {
      this.setSwitch("PLATFORM", 0, e);
    },
    // 企业开关
    onCorpSwitchChange(id, status) {
      this.setSwitch("CORP", id, status);
    },
    // 项目开关
    onProjectSwitchChange(id, status) {
      this.setSwitch("PROJECT", id, status);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.main {
  width: 100%;
  height: 100%;
  .fun {
    padding: 14px;
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      h2 {
        margin-right: 20px;
      }
    }
    .radio {
      margin: 15px 0 20px;
    }
  }
  .cont {
    padding: 0 14px;
    width: 100%;
  }
}
</style>