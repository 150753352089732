<template>
  <div class="main">
    <div class="fun">
      <Button type="success" @click="toAddWorkType">在当前添加工种</Button>
    </div>
    <div class="step" @click="toParent">
      <Breadcrumb>
        <BreadcrumbItem>工种父类</BreadcrumbItem>
        <BreadcrumbItem v-if="parentData.name">{{
          parentData.name
        }}</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="list-box" v-if="parentFlag">
      <div class="item" v-for="(item, index) in mainList" :key="index">
        <div class="label">{{ item.name }}</div>
        <div class="control">
          <Button
            class="c-btn"
            size="small"
            @click="toEditWorkType(item)"
            type="primary"
            >编辑</Button
          >
          <Button class="c-btn" size="small" @click="toSun(item)" type="info"
            >查看子类</Button
          >
          <Button class="c-btn" size="small" type="warning" @click="toDel(item)">删除</Button>
        </div>
      </div>
    </div>
    <div class="list-box" v-else>
      <div class="item" v-for="(item, index) in sunList" :key="index">
        <div class="label">{{ item.name }}</div>
        <div class="control">
          <Button
            class="c-btn"
            size="small"
            @click="toEditWorkType(item)"
            type="primary"
            >编辑</Button
          >
          <Button class="c-btn" size="small" type="warning" @click="toDel(item)">删除</Button>
        </div>
      </div>
    </div>
    <!-- 新增/编辑工种 -->
    <Modal
      v-model="editFlag"
      :loading="editNext"
      title="工种"
      @on-ok="addWorkType"
    >
      <div class="e-item">
        <Input v-model="currentData.name" placeholder="工种名称" />
      </div>
      <div class="e-item">
        <Input v-model="currentData.sort" placeholder="排序" />
      </div>
      <div class="e-item">
        <Input v-model="currentData.value" placeholder="值" />
      </div>
      <div class="e-item">
        <Input
          v-model="currentData.remark"
          maxlength="100"
          show-word-limit
          type="textarea"
          placeholder="备注"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "worktype",
  data() {
    return {
      mainList: [],
      sonList: [],
      editFlag: false,
      addOrEdit: "add",
      editNext: true,
      parentData: {},
      parentFlag: true,
      currentData: {
        parentId: "", //	是	string	父级id （1级分类传0）
        name: "", //	是	string	工种名称
        value: "", //	是	string	值
        sort: "", //	否	int	排序
        remark: "", //	否	string	备注
      },
    };
  },
  mounted() {
    this.getWorkTypeList();
  },
  methods: {
    getWorkTypeList() {
      let data = {};
      this.$fetch("/sapi/workType/tree/list", {
        method: "get",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.mainList = res.data;
            console.log(res.data);
          } else {
            this.$Message.error("获取工种失败");
          }
        })
        .catch((err) => {
         // this.$Message.error("获取工种失败");
        });
    },
    toAddWorkType() {
      this.addOrEdit = "add";
      this.currentData = {
        parentId: this.parentData.id ? this.parentData.id : 0,
        name: "",
        value: "",
        sort: "",
        remark: "",
      }
      this.editFlag = true
    },
    toEditWorkType(row) {
      this.addOrEdit = "edit";
      console.log(row);
      this.currentData = {
        id: row.id,
        parentId: row.parentId ? row.parentId : 0,
        name: row.name,
        value: row.value,
        sort: row.sort,
        remark: row.remark,
      }
      this.editFlag = true
    },
    addWorkType(type) {
      if (type == "cancel") {
        this.editFlag = false;
      }
      let apiUrl = "";
      apiUrl =
        this.addOrEdit == "add"
          ? "/sapi/workType/save"
          : "/sapi/workType/update";
      let data = {};
      if (this.addOrEdit == "add") {
        data = {
          parentId: this.parentData.id ? this.parentData.id : 0, //	是	string	父级id （1级分类传0）
          name: this.currentData.name, //	是	string	工种名称
          value: this.currentData.value, //	是	string	值
          sort: this.currentData.sort, //	否	int	排序
          remark: this.currentData.remark, //	否	string	备注
        };
      }
      if (this.addOrEdit == "edit") {
        data = {
          id: this.currentData.id, //	是	string	编号
          parentId: this.currentData.parentId, //	否	string	父级id
          name: this.currentData.name, //	否	string	工种名称
          value: this.currentData.value, //	否	int	值
          sort: this.currentData.sort, //	否	int	排序
          remark: this.currentData.remark, //	否	string	备注
        };
      }
      this.$fetch(apiUrl, {
        method: "post",
        data,
      })
        .then((res) => {
          if (res.code == "200") {
            this.$Message.success("操作成功");
            this.toParent();
            this.getWorkTypeList()
            this.editFlag = false;
          } else {
            this.$Message.error("操作失败");
            this.editNext = false;
            setTimeout(() => {  
              this.editNext = true;
            }, 100);
            return false;
          }
        })
        .catch((err) => {
         // this.$Message.error("操作失败");
          this.editNext = false;
          setTimeout(() => {
            this.editNext = true;
          }, 100);
          return false;
        });
    },
    toParent() {
      this.parentData = {};
      this.parentFlag = true;
    },
    toSun(row) {
      this.parentData = row;
      this.sunList = row.childs;
      this.parentFlag = false;
    },
    toDel(row){
      this.$Modal.confirm({
        title: "提示",
        content: "<p>是否移除该工种</p>",
        onOk: () => {
          // this.$axios.delete(`/sapi/workType/${row.id}`)
          this.$axios.post(`${this.apiUrlCros}/sapi/workType/${row.id}`)
          .then((res) => {
            // console.log(res)
            if (res.data.code == "200") {
              this.$Message.success("操作成功");
              this.getWorkTypeList()
            } else {
              this.$Message.info("操作失败");
            }
          }).catch((err)=>{
            this.$Message.info(err.response.data.msg)
          })
        },
        onCancel: () => {},
      });
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.e-item {
  width: 100%;
  margin-bottom: 14px;
}
.main {
  width: 100%;
  height: 100%;
  .fun {
    padding: 14px;
  }
  .step {
    padding: 14px;
    width: 100%;
  }
  .list-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    .item {
      color: #409EFF;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px;
      font-size: 16px;
      border-top: 1px solid #e4e7ed;
      .label {
        width: 150px;
      }
      .control {
        flex: 1;
        text-align: right;
        .c-btn {
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>